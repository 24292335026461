/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "~jsoneditor/dist/jsoneditor.min.css";
@import '/node_modules/ngx-bar-rating/themes/br-movie-theme';
@import '/node_modules/ngx-bar-rating/themes/br-square-theme';
@import 'assets/br-deyda-theme';
textarea.jsoneditor-text {
    min-height: 350px;
    max-height: 360px;
}

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

@media print {

    /* on modal open bootstrap adds class "modal-open" to body, so you can handle that case and hide body */
    body.modal-open {
        visibility: hidden;
    }

    body.modal-open .modal .modal-header,
    body.modal-open .modal .modal-body {
        visibility: visible;
        /* make visible modal body and header */
    }

        .modal {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            min-height: 550px
        }
    

        // @page {
        //     margin: 0;
        //     size: A4;
        //  }
        // body {
        //     width: 210mm;
        //     /* Ancho de A4 */
        // }
        

        // .modal {
        // width: 210mm !important;
        //     /* Ancho de una hoja A4 */
        //     margin: auto;
        //     /* Centrar horizontalmente */
                    
        // }

        // .modal-content {
        //     width: 100% !important;
        //     /* Establecer el ancho del contenido del modal como automático */
        //     max-width: none;
        //     /* No establecer un ancho máximo para el contenido del modal */
        // }

        // //    .modal {
        // //        margin: 0;
        // //        padding: 0;
        // //        min-height: 550px;
        // //        visibility: visible;
        // //        /**Remove scrollbar for printing.**/
        // //        overflow: visible !important;
        // //        position: relative;
        // //    }
    
        //    .modal-dialog {
        //        visibility: visible !important;
        //        /**Remove scrollbar for printing.**/
        //        overflow: visible !important;
        //    }
}

.br-deyda {

    .br-unit {
        .br-unit-inner {
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-left: 5px;
            width: 28px;
            height: 28px;
            filter: grayscale(1);
        }

        &:first-child .br-unit-inner {
            margin-left: 0;
            background-image: url('./custom/malo.png');
        }

        &:nth-child(2) .br-unit-inner {
            background-image: url('./custom/regular.png');
        }

        &:nth-child(3) .br-unit-inner {
            background-image: url('./custom/bueno.png');
        }

        &:nth-child(4) .br-unit-inner {
            background-image: url('./custom/exelente.png');
        }    

        // &:nth-child(4) {
        //     background-image: url('/./custom/004-squirtle.svg');
        // }
        // &:nth-child(5) {
        //     background-image: url('/./custom/003-bellsprout.svg');
        // }
    }

    .br-unit-inner.br-active {
        filter: grayscale(0);
    }
}